// Utilities

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-hide\@small {
  display: none;

  @include bp-medium {
    display: block;
  }
}
