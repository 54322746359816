// Links

a {
  color: $color-link;
  text-decoration: underline;
  @include transition();

  &:hover {
    color: $color-text;
  }
}
