/*
  Project: Senteler
  Author: Dawid Kotlarz
 */
@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "tools/_mixins.scss";
@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_reset.scss";
@import "generic/_shared.scss";
@import "elements/_headings.scss";
@import "elements/_hr.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_page.scss";
@import "objects/_list-bare.scss";
@import "components/_footer.scss";
@import "components/_layout.scss";
@import "trumps/_utilities.scss";
@import 'bulma/bulma';
@import '../assets/lightbox2/dist/css/lightbox.min.css';
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400');

body {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  line-height: 1.75;
  color: $color-text;
}

main a {
  color: $color-text;
  text-decoration: none;
  @include transition();
  position: relative;

  &:hover {
    color: $color-text;
  }

  &:after {
    content: '';
    background: $color-text;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover:after{
    content: '';
    background: $color-white;
    height: 0;
  }

}

.columns {
  margin: 0;
}

.column {
  padding: 0;
}

header {
  position: fixed;
  right:0;
  top: 0;
  height: 100%;
  color: $color-white;
  background: $color-yellow;
  width: 336px;
}

.bg-inside {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $color-yellow;
  right: -336px;
  //z-index: 2;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.fixed-size {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 336px;
}

.logo{
  width: 200px;
  height: 99px;
  background: url('../assets/images/logo/Senteler.svg') no-repeat;
  background-size: contain;
  margin: 1.667vw 0 73px 2.605vw;
  a{
    display: block;
    width: inherit;
    height: inherit;
  }
}
.yellow{
  header, .bg-inside, .footer-box-mobile{
    background: $color-yellow;
  }
  .logo {
    background: url('../assets/images/logo/Senteler.svg') no-repeat;
    background-size: contain;

  }
  h2, h3{
    color: $color-text;
  }
}
.blue{
  header, .bg-inside, .footer-box-mobile{
    background: $color-blue;
  }
  .logo {
    background: url('../assets/images/logo/Senteler-3.svg') no-repeat;
    background-size: contain;
  }
  h2, h3{
    color: $color-blue;
  }
  a:hover{
    color: $color-blue;
    text-decoration: none;
  }
}
.red{
  header, .bg-inside, .footer-box-mobile{
    background: $color-red;
  }
  .logo{
    background: url('../assets/images/logo/Senteler-1.svg') no-repeat;
    background-size: contain;
  }
  .btn {
    color: $color-red;
    background: $color-white;
    border: 1px solid $color-red;

    &:hover {
      background: $color-red;
      color: $color-white;

    }
  }
  h2, h3{
    color: $color-red;
  }
  a:hover{
    color: $color-red;
    text-decoration: none;
  }
}

.green {
  .logo {
    background: url('../assets/images/logo/Senteler-2.svg') no-repeat;
    background-size: contain;
  }

  header, .bg-inside, .footer-box-mobile {
    background: $color-green;
  }

  h2, h3 {
    color: $color-green;
  }

  .btn {
    color: $color-green;
    background: $color-white;
    border: 1px solid $color-green;

    &:hover {
      background: $color-green;
      color: $color-white;

    }
  }
  a:hover{
    color: $color-green;
    text-decoration: none;
  }
}

h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 10px;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.c-nav-main {
  ul {
    margin:0;
    padding: 30px 36px 0 0;
    text-align: right;

    .anime {
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);

    }
      li {
        transform: translate3d(340px,0,0);
        -webkit-transform: translate3d(340px,0,0);
        -ms-transform: translate3d(340px,0,0);
        transition: transform 0s 0.4s;

        &:nth-child(2) {
          transition-delay: 0.160s;
          -webkit-transition-delay: 0.160s;
        }

        &:nth-child(3) {
          transition-delay: 0.310s;
          -webkit-transition-delay: 0.310s;
        }

        &:nth-child(4) {
          transition-delay: 0.360s;
          -webkit-transition-delay: 0.360s;
        }

        &:nth-child(5) {
          transition-delay: 0.410s;
          -webkit-transition-delay: 0.410s;
        }

      &.current_page_item {
        a:after {
          content: '';
          background: $color-white;
          height: 1px;
          width: 100%;
          position: absolute;
          left:0;
          right:0;
          bottom: -5px;
        }
      }

      a {
        font-size: 45px;
        font-weight: 300;
        position: relative;
        color: $color-white;
        text-decoration: none;

        &:after {
          content: '';
          @include transition();
        }

        &:hover {
          color: $color-white;
        }

        &:hover:after {
          content: '';
          background: $color-white;
          height: 1px;
          width: 100%;
          position: absolute;
          left:0;
          right:0;
          bottom: -5px;
        }
      }
    }
  }
}

.yellow h1, .home h1 {
  color: $color-text;
}

.red h1 {
  color: $color-red;
}

.green h1 {
  color: $color-green;
}

.blue h1 {
  color: $color-blue;
}

.home .top-text {
  p {
    //margin-top: 50px;
  }
}

.top-text {
  padding-bottom: 45px;

  p {
    margin-top: 30px;
  }
}

#post-5 {
  .top-text {
    p {
      margin-top: 13px;
    }
  }
}

#post-10 {
  .top-text {
    p {
      margin-top: 0;
    }
  }
}

.rest {
  display: block;
}

.im {
  display: none;
}

.page-id-6 {
  .im {
    display: block;
  }

  .rest {
    display: none;
  }
}

.home .bg-image {
  height: 597px;
}

.bg-image {
  height: 474px;
  width: 100%;
  @include box-shadow(0,3px, 50px, rgba(0, 0, 0, 0.16));
}

.page_container {
  padding: 0 5.209vw;
}

.btn {
  color: $color-text;
  border: 1px solid $color-text;
  padding: 7px;
}

.slider {
  position: relative;
  height:374px;
  overflow: hidden;

  .slide {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;

    &:hover {
      .lightbox-item {
        bottom: 5%;
      }
    }
  }

  .slide-1 {
    display: block;
  }

  .slide-prev, .slide-next {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 48%;
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }

  .slide-prev {
    background: url('../assets/images/icons/slide-prev.svg') no-repeat;
    left: 3%;
  }

  .slide-next {
    background: url('../assets/images/icons/slide-next.svg') no-repeat;
    right: 3%;
  }

  a {
    display: block;
    width: inherit;
    height: inherit;
  }

  .lightbox-link {
    display: block;
    width: inherit;
    height: inherit;
  }

  .lightbox-item {
    background: url('../assets/images/icons/Group 170.svg') no-repeat;
    width: 40px;
    height: 40px;
    bottom: -15%;
    right: 3%;
    position: absolute;
    @include transition;
  }
}

.links {
  padding-bottom: 50px;
  display: inline-block;
}

.link-ferien {
  display: inline-block;

  &.active:after {
    content: '';
    height: 0;
  }

  &:after {
    bottom: 5px;
  }

  &.active {
    color: $color-red !important;
  }
}

.link-immobilien {
  display: none;
}

.ferien {
  display: none;
}

.sublayout {
  display: none;
}

.sublayout-1 {
  display: block;
}

.columns {
  margin: 0;
}

.text {
  //padding: 10px;
}

.text-middle {
  padding: 65px 0;
}

.is-centered {
  align-items: center;
  -webkit-align-items: center;
}

.items {
  .columns {
    margin-bottom: 56px;
  }

  .slider, .col-shadow {
    @include box-shadow(0, 3px, 50px, rgba(0, 0, 0, 0.16));
  }

  .page_container {
    padding: 0 3.907vw;
  }

  .text-box {
    padding: 55px;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .pdfs {
    margin: 40px 0 10px;
    .btn {
      margin-right: 10px;
    }
  }
}

.repeats {
  .text {
    padding: 80px 0 100px;
  }

  h2 {
    font-size: 30px;
    font-weight: 400;
  }
}

input, textarea, select {
  &:focus {
    outline: none;
  }
}

.form-offer {
  padding: 55px 0 40px 0;

  .padd-left {
    padding-left: 15px;
  }
}

.form-offer, .form-jobs {
  background: $color-light-grey;
  @include box-shadow(0, 3px, 50px, rgba(0, 0, 0, 0.16));
  color: $color-white;

  label {
    display: block;
  }

  input[type="text"], textarea, select {
    width: 100%;
    color: $color-light-grey;
    background: $color-white;
    font-size: 20px;
  }

  input, textarea, select {
    margin-top: 6px;
    padding: 1px 6px;
    border: 0;
    //@include placeholder($color-light-grey);
  }

  input[type="checkbox"] {
    margin: 2px 10px 0 0;
    width: 18px;
    height: 18px;
    background: $color-white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 3px solid $color-white;

    &:checked {
      background: $color-light-grey;
    }
  }

  select {
    width: 72%;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: $color-white url('../assets/images/icons/arrow_select.png') no-repeat right 5px center;
  }

  h2 {
    color: $color-white;
    margin-bottom: 50px;
  }

  .submit {
    background: $color-white;
    color: $color-light-grey;
    border: 1px solid $color-white;
    padding: 0 10px;
    float: right;
    @include transition();

    &:hover {
      background: $color-light-grey;
      color: $color-white;
      cursor: pointer;
    }
  }
}

.form-jobs {
  padding: 20px 25px;
  //margin-top: 120px;

  h2 {
    margin-bottom: 30px;
  }

  .input-label {
    border: 1px solid $color-white;
    display: inline-block;
    width: auto;
    padding: 1.5px 10px;
    margin-top: 6px;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/*
.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}
.inputfile + label {
  cursor: pointer;
}
.inputfile + label * {
  pointer-events: none;
}
*/

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.inputfile + label * {
  pointer-events: none;
}

.summary {
  color: darkgreen;
  clear:both;
  margin-top: 10px;

}

.loader {
  display: none;
  clear:both;
  float: right;
  border: 2px solid #fff;
  margin-top: 10px;
}

input.error {
  border: 1px solid red;
}

label.error {
  font-size: 12px;
  color: red;
}

.jobs {
  .top-text {
    padding-right:20px;
  }
}

.persons {
  p {
    font-size: 15px;
  }

  .is-margin {
    margin: 0 -35px;
  }

  .column {
    //margin: 0 20px;
    padding: 0 35px 20px 35px;
  }

  .image {
    overflow: hidden;
    position: relative;
    padding-bottom: 62%;

    img {
      position: absolute;
    }
  }
}

.footer-box {
  position:absolute;
  bottom: 0;
  width:100%;
  padding: 20px 36px 30px 0;
  text-align: right;
  transform: translate3d(340px,0,0);
  -webkit-transform: translate3d(340px,0,0);
  -webkit-transition: transform 0s 0.46s;
  transition: transform 0s 0.46s;
  transition-delay: 0.460s !important;
  -webkit-transition-delay: 0.460s !important;

  &.anime {
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .text {
    padding: 0;
  }

  .fb a {
    &:after {
      content: '';
      @include transition();
    }

    &:hover:after {
      content: '';
      height: 0;
    }
  }

  a {
    color: $color-white;
    display: inline-block;
    text-decoration: none;
    position: relative;

    &:hover {
      text-decoration: none;
      color: #fff;

    }

    &:after {
      content: '';
      @include transition();
    }

    &:hover {
      color: $color-white;
    }

    &:hover:after {
      content: '';
      background: $color-white;
      height: 1px;
      width: 100%;
      position: absolute;
      left:0;
      right:0;
      bottom: 2px;
    }


    &.link {
      margin: 20px 0 10px 0;
    }
  }
}

.rel {
  position: relative;
}

.arrow-down {
  width: 40px;
  height: 40px;
  background: url('../assets/images/icons/arrow-down.svg') no-repeat;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
}

.navbar_btn {
  display: none;
  position: absolute;
  width: 28px;
  height: 27px;
  z-index: 10;

  &[aria-expanded="false"] {
    background: url('../assets/images/icons/close.png') no-repeat;
    top: 63px;
    left: 20px;
  }

  &[aria-expanded="true"] {
    background: url('../assets/images/icons/navbar.svg') no-repeat;
    top: 66px;
    left: 40px;
    width: 37px;
  }
}

.footer-mobile {
  display: none;
}

.col-image {
  margin-top: -30px;
}

@media(max-width:1400px) {
  .fixed-size, header {
    width: 113px;
  }

  .c-nav-main li.anime, .footer-box.anime {
    transform: translate3d(340px, 0, 0);
    -webkit-transform: translate3d(340px, 0, 0);
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
  }

  header {
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
    transition-delay:0.6s;
    -webkit-transition-delay:0.6s;

    &.wide {
      width: 336px;
      transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;

      .c-nav-main li.anime, .footer-box.anime {
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }

  .navbar_btn {
    display: block;
  }
}

@media screen and (max-width: 1640px) and (min-width: 1000px) {
  .is-block-desktop-only-custom {
    display: block !important;
  }
}

@media(max-width: 1192px) {
  .column {
    display:block;
  }

  .col-bottom {
    order: 1;
  }

  .footer-box {
    padding: 10px 36px 10px 0;
    a.link {
      margin: 10px 0 5px 0;
    }
  }
}

@media(max-width: 1000px) {
  .form-jobs {
    margin-bottom: 30px;
  }

  .arrow-down {
    display: none;
  }
}

@media(max-width: 768px) {
  .form-offer select {
    width:100%;
  }

  .form-offer h2 {
    margin-bottom: 25px;
    margin-top: 15px;
  }

  .form-offer .padd-left{
    padding-left: 0;
  }

  .bg-image {
    height: 264px;
  }

  .fixed-size, header {
    width: 12px;
  }

  .col-image {
    width: 60%;
    margin: 30px auto 0 auto;
  }

  .navbar_btn {
    &[aria-expanded="false"] {
      background: url('../assets/images/icons/close.svg') no-repeat;
      top: 33px;
      left: auto;
      right: 36px;
      z-index:20;
    }

    &[aria-expanded="true"] {
      background: url('../assets/images/icons/navbar_dark.svg') no-repeat;
      top: 33px;
      left: -62px;
      width: 37px;
    }
  }

  .c-nav-main {
    padding-top: 30px;
  }

  header {
    z-index:5;
    transition: width 0.5s;
    transition-delay:0.6s;

    &.wide {
      width: 100%;
      transition-delay: 0.1s;

      .c-nav-main li.anime, .footer-box.anime {
        //transform: translate3d(0, 0, 0);
      }

      .footer-box {
        display: none;
      }
    }
  }

  body {
    font-size: 15px;
    line-height: 1.66;
  }

  .logo-c {
    position: fixed;
    width:100%;
    background: $color-white;
    z-index:1;

    .logo {
      width: 110px;
      height: 55px;
      margin: 19px 0 19px 22px;
    }
  }

  main {
    padding-top: 93px;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 1.33;
  }

  h2 {
    font-size: 20px;
  }

  .items h2 {
    font-size: 20px;
  }

  .home .bg-image {
    height: 204px;
  }

  .text-middle {
    padding: 40px 0;
  }

  .items .text-box {
    padding: 25px;
  }

  .slider {
    height: 280px;
  }

  .repeats .text {
    padding: 40px 0 50px;
    word-break: break-word;
  }

  .footer-mobile {
    display: block;
  }
}

.footer-mobile {
  .footer-box-mobile {
    width: 100%;
    color: #fff;
    text-align: right;
    padding: 30px 15px 30px;
    font-size: 15px;
  }

  a {
    color: $color-white;
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $color-white;
    }
  }

  a.link {
    margin: 20px 0 0 0;
    display: block;
  }
}

@media (max-height: 750px) {
  .footer-box {
    display: none;
  }

  .footer-mobile {
    display: block;
  }
}

@media print {
  @media print {
    body {-webkit-print-color-adjust: exact;}
  }

  @page {
      size: auto;   /* auto is the initial value */
      margin: 20mm 10mm;
      -webkit-print-color-adjust: exact;
      page-break-before: auto;
      page-break-after: auto;

  }

  header, .form-jobs, .form-offer, .slider .slide-prev, .slider .slide-next {
    display: none;
  }

  .yellow header, .yellow .bg-inside, .yellow .footer-box-mobile {
    background: none;
  }

  .animate {
    display: block !important;
    opacity: 1 !important;
    transform: translateX(0) !important;
    transition: all 0.1s ease, opacity 0.1s ease !important;
  }

  .items .slider, .items .col-shadow {
    box-shadow: none;
  }

  .slider {
    margin-top: 350px;
    width: 100%;
    display: block;
    float: none;
  }

  .clear {
    display: none;
  }
}