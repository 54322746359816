// Headings

h1, h2 {
  color: $color-text;
  font-weight: 800;
  line-height: 1.375;
  margin: 0 0 2rem;
}

h1 {
  font-size: 40px;
  font-weight: 300;
}

h2 {
  font-size: 25px;
  font-weight: 400;
}
