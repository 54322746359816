// Colors
$color-white: #fff;
$color-black: #000;
$color-light-grey: #D1CBC4;


// Text
$color-bg: $color-white;
$color-text: #A29D96;
$color-yellow: #FBD009;
$color-blue: #172983;
$color-red: #BD311A;
$color-green: #009640;

// Links
$color-link: #fff;

// Borders
$color-border: #000;
